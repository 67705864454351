/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W październiku 2021 roku rozpoczęły się prace związane z inwestycją budowy dróg w gminie Krzymów. Realizacja miała na celu przede wszystkim poprawę bezpieczeństwa, z poprzez stworzenie alternatywnej drogi dojazdowej do drogi krajowej nr 92."), "\n", React.createElement(_components.p, null, "W ramach zadania zostały zbudowane następujące elementy:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "jezdnia z mieszanki mineralno- asfaltowej,"), "\n", React.createElement(_components.li, null, "zjazdy publiczne z mieszanki mineralno- asfaltowej,"), "\n", React.createElement(_components.li, null, "zjazdy indywidualne z kostki betonowej,"), "\n", React.createElement(_components.li, null, "wyspy dzielące z kostki betonowej koloru czerwonego, palisady"), "\n", React.createElement(_components.li, null, "przebudowa skrzyżowania z DK92, lewoskręty, rowy przydrożne, krawężniki, obrzeża, oporniki"), "\n", React.createElement(_components.li, null, "oznakowanie poziome, pionowe, wycinka drzew"), "\n", React.createElement(_components.li, null, "usunięcie kolizji telekomunikacyjnej"), "\n", React.createElement(_components.li, null, "przebudowa i zabezpieczenie sieci telekomunikacyjnej"), "\n", React.createElement(_components.li, null, "wykonanie chodnika z kostki brukowej betonowej koloru szarego,"), "\n"), "\n", React.createElement(_components.p, null, "Droga została oddana do użytku w lipcu 2022 roku."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
